<template>
  <el-scrollbar :native="false" style="margin-top: 34px">
    <div style="max-height: calc(100vh - 34px);">
      <el-form
        ref="dnisPoolForm"
        :rules="rules"
        :model="dnispool"
        label-position="top"
        size="large"
      >
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <page-header :title="__('DNIS Pool')" :contentId="id" />
          </el-col>
        </el-row>

        <el-row type="flex">
          <el-col :span="6"></el-col>
          <el-col :span="9">
            <el-form-item
              prop="pool_name"
              :label="__('DNIS Pool Name')"
              required
            >
              <el-input
                :value="dnispool.pool_name"
                @input="$set(dnispool, 'pool_name', $event)"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="6"></el-col>
          <el-col :span="9">
            <el-form-item :label="__('Phone Numbers')" required>
              <el-radio-group v-model="addMethod" class="display-inline">
                <el-radio label="range">{{ __("Add number range") }}</el-radio>
                <el-radio label="upload">{{ __("Upload CSV file") }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="addMethod === 'upload'">
          <el-col :span="12" :offset="6">
            <el-form-item>
              <el-button @click="downloadTemplate"
                >{{ __("Download template") }}
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="6"></el-col>
          <el-col :span="9">
            <el-form-item
              :label="__('DNIS Pool Range')"
              v-if="addMethod === 'range'"
            >
              <div style="display: flex;">
                <el-input
                  v-model="rangeFrom"
                  prop="from_phone_number"
                ></el-input>
                <div style="width: 50px; padding: 0 5px">{{ __("To") }}</div>
                <el-input v-model="rangeTo" prop="to_phone_number"></el-input>
              </div>
            </el-form-item>
            <el-form-item v-else-if="addMethod === 'upload'" prop="file_path">
              <csv-uploader
                :fileUrl="dnispool.file_path"
                :fileName="file_name"
                folder="uploads"
                fit="compact"
                @on-success="handleSuccess"
                @on-error="handleError"
                @on-progress="isLoading = true"
                @on-delete="handleFileDelete"
              />
            </el-form-item>
            <el-form-item :label="__('Phone Number Range')" v-else>
              <div style="display: flex;">
                <el-input
                  v-model="rangeFrom"
                  prop="from_pool_number"
                ></el-input>
                <div style="width: 50px; padding: 0 5px">To</div>
                <el-input v-model="rangeTo" prop="to_pool_number"></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex" v-if="id !== -1">
          <el-col :span="6"></el-col>
          <el-col :span="9">
            <el-form-item :label="__('Insert Numbers Option')">
              <el-radio-group v-model="insertMethod" class="display-inline">
                <el-radio label="append">{{
                  __("Append to existing numbers")
                }}</el-radio>
                <el-radio label="replace">{{
                  __("Replace existing numbers")
                }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <el-form-item>
              <save-button
                type="primary"
                v-loading="isLoading"
                @click="submitForm"
                :disabled="ifValidData || !canWrite()"
                class="submitBtn"
                :primaryKey="id"
              />
              <el-button class="cancelBtn" @click="handleCancelBtn"
                >{{ __("Cancel") }}
              </el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </el-scrollbar>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import { mapState, mapActions } from "vuex";
import CsvUploader from "@/components/uploaders/types/CsvUploader";
import { EventBus } from "@/EventBus";
import { removeFiles } from "@/api/services";
import _ from "lodash";
import { validatePool } from "@/api/dnispool";
import { getDnisPoolUploadTemplate } from "@/api/dnispool";
import FileDownloader from "@/mixins/FileDownloader";
import SaveButton from "@/components/SaveButton";

export default {
  name: "createOrEditForm",
  mixins: [FileDownloader],
  components: {
    PageHeader,
    CsvUploader,
    SaveButton
  },
  props: {
    dnispool: {
      type: Object,
      required: true
    },
    poolInfo: {
      type: Object,
      required: true
    },
    id: {
      type: Number,
      required: false,
      default: -1
    }
  },
  data() {
    let validatePoolName = async (rule, value, callback) => {
      let hasError = false;
      await validatePool(value)
        .then(res => {
          hasError = res.data.found && value !== this.poolInfo.pool_name;
        })
        .catch(() => {
          callback();
        });
      if (hasError) {
        callback(new Error(__("Pool name already exists")));
      } else {
        callback();
      }
    };

    return {
      rules: {
        pool_name: [
          {
            required: true,
            trigger: "blur",
            message: __("Pool Name is required")
          },
          {
            validator: validatePoolName,
            trigger: "blur"
          }
        ]
      },
      addMethod: "range",
      insertMethod: "append",
      rangeFrom: "",
      rangeTo: "",
      file_name: "",
      uploadedFilePaths: [],
      isSubmitting: false,
      isLoading: false // to disable submit button while the upload is in progress
    };
  },

  computed: {
    ...mapState("app", {
      selectedAccountId: state => state.selectedAccountId
    }),
    getNumberRange() {
      return this.rangeFrom + "-" + this.rangeTo;
    },
    ifValidData() {
      if (this.canWrite) {
        if (this.id === -1) {
          return (
            (this.getNumberRange === "-" && this.addMethod === "range") ||
            (this.dnispool.file_path === "" && this.addMethod === "upload")
          );
        }
      }
      return false;
    }
  },
  methods: {
    ...mapActions("dnispool", {
      createDnisPool: "createDnisPool",
      updateDnisPool: "updateDnisPool",
      deleteContentMethod: "deleteDnisPool",
      undoDeleteContent: "undoDeleteDnisPool"
    }),
    downloadTemplate() {
      getDnisPoolUploadTemplate().then(response => {
        this.downloadFile(response.data, `dnis_pool_template_csv_data.csv`);
      });
    },
    handleError(err) {
      console.log(err);
      this.isLoading = false;
    },
    handleSuccess(url) {
      this.isLoading = false;
      this.uploadedFilePaths.push(url.path);
      this.$set(this.dnispool, "file_path", url.path);
      this.file_name = url.uploaded_file_name;
    },
    handleFileDelete() {
      this.resetFileFormContent();
    },
    resetFileFormContent() {
      this.$set(this.dnispool, "file_path", "");
      this.file_name = "";
    },
    removeUploadedFiles() {
      removeFiles(this.uploadedFilePaths);
    },
    handleCancelBtn() {
      this.resetForm("dnisPoolForm");
      this.$emit("cancel");
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    submitForm() {
      this.isSubmitting = true;
      this.$refs.dnisPoolForm.validate(valid => {
        if (valid) {
          const process =
            this.id === -1 ? this.createDnisPool : this.updateDnisPool;

          this.$set(this.dnispool, "ac_id", this.selectedAccountId);
          if (this.id !== -1 && this.insertMethod !== "") {
            this.$set(this.dnispool, "insert_option", this.insertMethod);
          }

          if (this.addMethod === "range") {
            this.$set(
              this.dnispool,
              "pool_numbers",
              this.rangeFrom + "-" + this.rangeTo
            );
          } else if (this.addMethod === "upload") {
            this.$set(this.dnispool, "pool_numbers", this.dnispool.file_path);
          }
          let dnispool = _.cloneDeep(this.dnispool);
          process({ dnispool })
            .then(data => {
              this.isSubmitting = false;
              console.log(data);
              this.id === -1
                ? this.$message({
                    type: "success",
                    message: data.message
                  })
                : this.$message({
                    type: "success",
                    message: data.message
                      ? data.message
                      : __("Dnis Pool updated successfully")
                  });
              EventBus.$emit("list-changed", data.data);
              this.handleCancelBtn();
            })
            .catch(err => {
              console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        } else {
          this.isSubmitting = false;
          return false;
        }
      });
    }
  },
  watch: {
    formAction: {
      immediate: true,
      deep: true,
      handler: function(val) {
        console.log(this.dnispool.pool_id, val);
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";
</style>
